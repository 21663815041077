import React from "react"
import PropTypes from "prop-types"

import { Link } from "components/anti/link/link"

const FooterSocial = ({ className }) => {
  const social = [
    {
      iconName: "ai-linkedin-in",
      url: "https://www.linkedin.com/company/shift-counsellors-at-law/about/",
      className: "social-linkedin",
    },
    {
      iconName: "ai-facebook-f",
      url: "https://www.facebook.com/SHIFT-113128010828373/",
      className: "social-facebook",
    },
    {
      iconName: "ai-instagram",
      url: "https://www.instagram.com/shiftlegal/",
      className: "social-instagram",
    },
  ]

  return (
    <ul className={`social ${className}`}>
      {social.map((item, i) => {
        return (
          <li key={i} className="social-item">
            <Link to={item.url} className={`social-link ${item.className}`}>
              <i className={`aib ${item.iconName}`} />
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

FooterSocial.propTypes = {
  className: PropTypes.string, // Set optional classes
}

FooterSocial.defaultProps = {
  className: "",
}

export default FooterSocial
