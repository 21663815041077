import React, { useContext } from "react"
import { navigate } from "gatsby"
import { InputTextV2 } from "components/anti/form/form"

import { LangContext } from "context/lang-context"

import { Container } from "components/anti/grid/grid"

const SearchMenu = ({ variant, handleSearch }) => {
  const { lang } = useContext(LangContext)
  const navigateSearch = e => navigate(`/search?q=${e}`)
  return (
    <>
      <div className={`search-menu ${variant}`}>
        {/* <div className="search-menu-content"> */}
        <Container>
          <div className="form-group">
            <InputTextV2
              type="text"
              placeholder={
                lang === "EN"
                  ? "Start typing to search.."
                  : "Mulai mengetik untuk mencari.."
              }
              className="form-search"
              onKeyPress={navigateSearch}
            >
              <div className="form-search-icon">
                <i className="air ai-search" />
              </div>
              <div className="form-search-close" onClick={handleSearch}>
                <i className="air ai-times" />
              </div>
            </InputTextV2>
          </div>
        </Container>
        {/* </div> */}
      </div>
    </>
  )
}

export default SearchMenu
