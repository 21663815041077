import React from "react"

import FooterSocial from "./social"

const FooterAbout = ({ logo, text }) => {
  return (
    <div className="col-footer footer-about">
      <img src={logo} className="img-fluid" alt="Logo" />
      <p>{text}</p>
      <FooterSocial />
    </div>
  )
}

export default FooterAbout
