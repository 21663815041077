import React from "react"
import PropTypes from "prop-types"
import ReactModal from "react-modal"

export const Modal = ({
  id,
  isShowing,
  hide,
  img,
  imgClassName,
  imgAlt,
  title,
  titleClassName,
  text,
  textClassName,
  modalWrapperClassName,
  modalBodyClassName,
  className,
  children,
}) => {
  return (
    <ReactModal
      closeTimeoutMS={500}
      isOpen={isShowing === id && true}
      contentLabel="Modal"
      onRequestClose={hide}
      className={`${className}`}
      ariaHideApp={false}
    >
      <div className={`modal-wrapper ${modalWrapperClassName}`}>
        <div onClick={hide} className="close">
          <i className="fal fa-times"></i>
        </div>
        <div className={`modal-body p-0 ${modalBodyClassName}`}>
          {img && (
            <img
              src={img}
              className={`img-fluid ${imgClassName}`}
              alt={imgAlt}
            />
          )}
          <div className="p-box">
            {title && (
              <h2
                className={`h2 ${titleClassName} animated fadeInDown delayp3`}
              >
                {title}
              </h2>
            )}
            {text && (
              <p className={`${textClassName} animated fadeInDown delayp4`}>
                {text}
              </p>
            )}
            {children}
          </div>
        </div>
      </div>
    </ReactModal>
  )
}

Modal.propTypes = {
  // isShowing: PropTypes.bool,
  // hide: PropTypes.bool,
  img: PropTypes.any,
  imgClassName: PropTypes.string,
  imgAlt: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  text: PropTypes.string,
  textClassName: PropTypes.string,
  modalWrapperClassName: PropTypes.string,
  modalBodyClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
}

Modal.defaultProps = {
  imgClassName: "",
  imgAlt: "Modal Image",
  titleClassName: "",
  textClassName: "",
  modalWrapperClassName: "",
  modalBodyClassName: "",
  className: "",
}
