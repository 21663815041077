import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"

import { Container } from "components/anti/grid/grid"

import MobileMenu from "./components/mobile-menu"
import DesktopMenu from "./components/desktop-menu"
import SearchMenu from "./components/search-menu"

//Load Context
import { LangContext } from "context/lang-context"

// import logoDark from "assets/img/brand/logo_dark.png"
import logoDark from "assets/img/brand/logo_dark.svg"

const Navbar = () => {
  // Navbar expand config
  // Change the const below & config on variables/navbar.scss when setting the .navbar-expand-*
  // e.g. sm, md, lg, xl, xxl
  const navExpand = "md"

  // IF NO NAVBAR EXPAND USED (no responsive navbar), do the following:
  // 1. In variables/navbar.scss, set $navbar-top-height-mobile & $navbar-top-height-desktop to 0
  // 2. In variables/navbar.scss, set $navbar-main-height-mobile & $nabvar-main-height-desktop to the same height
  // 3. In variables/navbar.scss, set $navbar-expand-up to ""
  // 4. In navbar.js, et const navbarExpand to ""

  // MENU - MOBILE
  // Open / close menu state & handler
  const [mobileMenu, setMobileMenu] = useState({
    initial: false,
    clicked: null,
  })
  // Toggler disabled state & handler
  const [disabledMenu, setDisabledMenu] = useState(false)
  const disableMenu = () => {
    setDisabledMenu(!disabledMenu)
    setTimeout(() => {
      setDisabledMenu(false)
    }, 1500) // set this with your mobile menu animation duration
  }
  const handleMenuMobile = () => {
    disableMenu()
    if (mobileMenu.initial === false) {
      setMobileMenu({ initial: null, clicked: true })
    } else if (mobileMenu.clicked === true) {
      setMobileMenu({ clicked: !mobileMenu.clicked })
    } else if (mobileMenu.clicked === false) {
      setMobileMenu({ clicked: !mobileMenu.clicked })
    }
  }

  useEffect(() => {
    const navToggler = document.querySelector(".nav-toggler")
    const navMobileMenu = document.querySelector(".mobile-menu")
    const menuBg = document.querySelector(".menu-bg")
    // prettier-ignore
    if (mobileMenu.clicked === false) {
      // Toggle inactive
      navToggler.classList.add("inactive")
      navMobileMenu.classList.remove("show")
      navMobileMenu.classList.add("hide")
      menuBg.classList.remove("show")
      menuBg.classList.add("hide")
    } else if (mobileMenu.clicked === true || (mobileMenu.clicked === true && mobileMenu.initial === null)) {
      // Toggle active
      navToggler.classList.remove("inactive")
      navMobileMenu.classList.add("show")
      menuBg.classList.add("show")
    }
  }, [mobileMenu])

  // SEARCH
  // Open / close menu state & handler
  const [search, setSearch] = useState({
    initial: false,
    clicked: null,
  })
  // Toggler disabled state & handler
  const [disabledSearch, setDisabledSearch] = useState(false)
  const disableSearch = () => {
    setDisabledSearch(!disabledSearch)
    setTimeout(() => {
      setDisabledSearch(false)
    }, 1500) // set this with your mobile menu animation duration
  }
  const handleSearch = () => {
    disableSearch()
    if (search.initial === false) {
      setSearch({ initial: null, clicked: true })
    } else if (search.clicked === true) {
      setSearch({ clicked: !search.clicked })
    } else if (search.clicked === false) {
      setSearch({ clicked: !search.clicked })
    }
  }
  useEffect(() => {
    const searchMenu = document.querySelector(".search-menu")
    const navbarMain = document.querySelector(".navbar-main")
    // prettier-ignore
    if (search.clicked === false) {
      // Toggle inactive
      searchMenu.classList.remove("show")
      searchMenu.classList.add("hide")
      navbarMain.classList.remove("search-show")
      navbarMain.classList.add("search-hide")
    } else if (search.clicked === true || (search.clicked === true && search.initial === null)) {
      // Toggle active
      searchMenu.classList.add("show")
      navbarMain.classList.add("search-show")
    }
  }, [search])

  // Language state handler
  const { setLang, lang } = useContext(LangContext)

  const handleLang = data => {
    setLang(data)
  }

  useEffect(() => {
    window.sessionStorage.setItem("shift-lang", lang)
  }, [lang])
  return (
    <>
      {/* When giving .navbar-expand-*, don't forget to modify the variables on _navbar.scss */}
      <nav className={`navbar navbar-expand-${navExpand} fixed-top`}>
        <div className="navbar-top">
          <Container>
            <ul className="col-left">
              <li className="nav-item">
                {lang === "EN" ? "Contact us at" : "Hubungi kami di"}

                <Link
                  className="nav-link hover-underline inverse"
                  // to="https://wa.me/6281223451234"
                >
                  (+62 21) 5030 0880
                </Link>
              </li>
            </ul>
            <ul className="col-right">
              <li className="nav-item">
                {/* cmiiw in this case u/adil */}
                <button
                  className={`nav-link text-uppercase lang-text ${lang ===
                    "EN" && "active"}`}
                  onClick={() => handleLang("EN")}
                >
                  English
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link text-uppercase lang-text ${lang ===
                    "ID" && "active"}`}
                  onClick={() => handleLang("ID")}
                >
                  Bahasa Indonesia
                </button>
              </li>
            </ul>
          </Container>
        </div>

        <div className="navbar-main">
          <Container>
            <ul className="col-left">
              <li className="nav-item-brand">
                {/* prettier-ignore */}
                <Link className="navbar-brand absolute" to="/">
                  <img src={logoDark} className="img-fluid" alt="Logo" />
                </Link>
              </li>
            </ul>

            {/* Mobile */}
            <ul className={`col-right d-${navExpand}-none`}>
              <li className="nav-item">
                <button
                  className="nav-icon search-toggler"
                  disabled={disabledSearch}
                  onClick={handleSearch}
                >
                  <i className="air ai-search" />
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-icon nav-toggler inactive absolute"
                  disabled={disabledMenu}
                  onClick={handleMenuMobile}
                >
                  <span className="icon-bar top-bar" />
                  <span className="icon-bar middle-bar" />{" "}
                  <span className="icon-bar bottom-bar" />
                </button>
              </li>
            </ul>

            {/* Desktop */}
            <DesktopMenu
              disabledSearch={disabledSearch}
              handleSearch={handleSearch}
              navExpand={navExpand}
            />
          </Container>
          <SearchMenu handleSearch={handleSearch} variant="expand" />
        </div>

        <MobileMenu
          mobileMenu={mobileMenu}
          navExpand={navExpand}
          handleLang={handleLang}
          variant="slide-right"
        />
        <div className="menu-bg" />
      </nav>
    </>
  )
}

export default Navbar
