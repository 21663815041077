import React, { useContext, useEffect, useState } from "react"

import FooterAbout from "./components/about"
import FooterContact from "./components/contact"
import FooterMenu from "./components/menu"
import FooterBottom from "./components/bottom"

import logo from "assets/img/brand/logo_dark.svg"

//Load Context
import { LangContext } from "../../../context/lang-context"
import { Button } from "../../anti/buttons/buttons"

const Footer = () => {
  const { lang } = useContext(LangContext)
  const [showBtn, setShowBtn] = useState(false)

  const menuLinks = [
    { title: "About", link: "/about" },
    {
      title: "Recognitions",
      link: "/recognitions",
    },
    {
      title: "Practice Areas",
      link: "/practice-areas",
    },
    {
      title: "Work With Us",
      link: "/work-with-us",
    },
    { title: "The Team", link: "/the-team" },
    { title: "Resources", link: "/resources" },
  ]

  const contactLinks = [
    {
      title: "(+62 21) 5030 0880",
      link: "tel:(+62 21) 5030 0880",
      icon: "ais ai-phone-alt",
    },
    {
      title: "(+62 21) 5030 0830",
      link: "tel:(+62 21) 5030 0830",
      icon: "ais ai-fax",
    },
    {
      title: "info@shiftlegal.id",
      link: "mailto:info@shiftlegal.id",
      icon: "ais ai-envelope",
    },
  ]

  const listenScrollEvent = () => {
    if (window.scrollY > 10) {
      setShowBtn(true)
    } else {
      setShowBtn(false)
    }
  }

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent)
  }, [])

  return (
    <footer>
      <section className="footer-main py-main">
        <div className="container">
          <div className="row row-4">
            <div className="col-lg-4">
              <FooterAbout
                logo={logo}
                text={
                  lang === "EN"
                    ? "We're here to bring a new approach and SHIFT the legal status quo. We redefine legal services by listening to you and tailor our services to your needs."
                    : "SHIFT hadir untuk membawa cara baru dan mengubah status quo layanan hukum. SHIFT mengubah layanan hukum dengan cara mendengarkan dan memberikan layanan yang sesuai dengan kebutuhan Anda."
                }
              />
            </div>
            <div className="col-lg-5">
              <FooterMenu
                title={lang === "EN" ? "Navigate" : "Navigasi"}
                links={menuLinks}
              />
            </div>
            <div className="col-lg-3">
              <FooterContact
                title={lang === "EN" ? "Contact" : "Kontak"}
                text={`Treasury Tower 10th Floor <br />
                District 8 – SCBD Lot 28 <br />
                Jl. Jend. Sudirman Kav. 52-53 <br />
                Jakarta 12190, Indonesia`}
                links={contactLinks}
              />
            </div>
          </div>
          <Button
            variant="primary"
            className={
              showBtn ? `back-to-top text-uppercase` : `back-to-top-hide`
            }
            onClick={scrollTop}
          >
            Back to Top
          </Button>
        </div>
      </section>
      <FooterBottom />
    </footer>
  )
}

export default Footer
