import React from "react"
import { Link } from "components/anti/link/link"

const FooterMenu = ({ title, links }) => {
  return (
    <div className="col-footer footer-menu">
      <h5>{title}</h5>
      <div className="navbar-nav">
        <div className="row">
          {links.map((link, i) => {
            return (
              <div className="col-sm-6">
                <div className="nav-item" key={i}>
                  <Link className="nav-link" to={link.link}>
                    {link.title}
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default FooterMenu
