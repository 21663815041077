import React, { useContext } from "react"
import CookieConsent from "react-cookie-consent"
import Button from "../../anti/buttons/buttons"

//Load Context
import { LangContext } from "context/lang-context"

const customStyles = {
  background: `#333333`,
  display: "block",
  maxWidth: "450px",
  left: "",
  right: "25px",
  marginBottom: "25px",
  padding: "1rem",
}

const customButton = {
  background: "#f79633",
  paddingLeft: "2rem",
  paddingRight: "2rem",
  borderRadius: "2px",
  float: "right",
}

const PopUpNew = () => {
  // Language state handler
  const { lang } = useContext(LangContext)
  return (
    <CookieConsent
      style={customStyles}
      buttonClasses="btn btn-primary text-white"
      buttonText={lang === "EN" ? "ACCEPT" : "TERIMA"}
      buttonStyle={customButton}
    >
      {lang === "EN" ? (
        <span>
          We use cookies to improve our website. To find out more, please read
          our{" "}
          <a href="/privacy-policy" className="font-weight-bold">
            {" "}
            Privacy Policy.{" "}
          </a>
          By continuing to visit our website, you agree to our use of cookies.
        </span>
      ) : (
        <span>
          Kami menggunakan cookies untuk meningkatkan kualitas situs web kami.
          Untuk mengetahui lebih lanjut, harap baca
          <a href="/privacy-policy" className="font-weight-bold">
            {" "}
            Kebijakan Privasi{" "}
          </a>{" "}
          kami. Dengan terus mengunjungi situs web kami, Anda menyetujui
          penggunaan cookies oleh kami.
        </span>
      )}
    </CookieConsent>
  )
}

export default PopUpNew
