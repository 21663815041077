import React from "react"
import { Link } from "components/anti/link/link"

const FooterContact = ({ title, text, links }) => {
  return (
    <div className="col-footer footer-contact">
      <h5>{title}</h5>
      <div dangerouslySetInnerHTML={{ __html: text }} className="mb-3" />

      {links.map((link, i) => {
        return (
          <div className="d-block">
            <Link key={i} to={link.link}>
              <i className={`${link.icon} pr-3 `} />
              {link.title}
            </Link>
          </div>
        )
      })}
    </div>
  )
}

export default FooterContact
