import React, { useContext } from "react"

import { Link } from "components/anti/link/link"
import { LangContext } from "context/lang-context"

const FooterBottom = () => {
  const { lang } = useContext(LangContext)
  return (
    <section className="footer-bottom">
      <div className="container">
        <div className="row w-100">
          <div className="col-md-6 col-left p-0">
            © 2021 SHIFT Counsellors At Law
          </div>
          <div className="col-md-6 col-right p-0">
            <Link to="/privacy-policy">
              {lang === "EN" ? "Privacy Policy" : "Kebijakan Privasi"}
            </Link>
            <span className="divider">|</span>
            <Link to="/terms-conditions">
              {lang === "EN" ? "Terms & Conditions" : "Syarat & Ketentuan"}
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FooterBottom
