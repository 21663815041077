import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"

//Load Context
import { LangContext } from "context/lang-context"

const DesktopMenu = ({ navExpand, handleSearch, disabledSearch }) => {
  const [dropdown, setDropdown] = useState({
    initial: false,
    clicked: null,
  })
  // Toggler disabled state & handler
  const [disabledDropdown, setDisabledDropdown] = useState(false)
  const disableDropdown = () => {
    setDisabledDropdown(!disabledDropdown)
    setTimeout(() => {
      setDisabledDropdown(false)
    }, 500) // set this with your mobile menu animation duration
  }
  const handleDropdown = () => {
    disableDropdown()
    if (dropdown.initial === false) {
      setDropdown({ initial: null, clicked: true })
    } else if (dropdown.clicked === true) {
      setDropdown({ clicked: !dropdown.clicked })
    } else if (dropdown.clicked === false) {
      setDropdown({ clicked: !dropdown.clicked })
    }
  }

  useEffect(() => {
    const navDropdown = document.querySelector(".nav-dropdown")
    const dropdownMenu = document.querySelector(".dropdown-menu")
    // prettier-ignore
    if (dropdown.clicked === false) {
      // Toggle inactive
      // navDropdown.classList.remove("active")
      dropdownMenu.classList.remove("show")
      dropdownMenu.classList.add("hide")
    } else if (dropdown.clicked === true || (dropdown.clicked === true && dropdown.initial === null)) {
      // Toggle active
      // navDropdown.classList.add("active")
      dropdownMenu.classList.add("show")
    }
  }, [dropdown])

  // Language state handler
  const { lang } = useContext(LangContext)

  //active dropdown class
  const [activeDropdown, setActiveDropdown] = useState(false)

  useEffect(() => {
    const dropdown = document.querySelector(".dropdown-menu")
    for (var i = 0; i < dropdown.childNodes.length; i++) {
      if (dropdown.childNodes[i].className == "dropdown-item active") {
        setActiveDropdown(true)
        break
      }
    }
  }, [activeDropdown])

  return (
    <>
      <div className={`desktop-menu d-none d-${navExpand}-flex`}>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link className="nav-link" activeClassName="active" to="/">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link nav-dropdown ${activeDropdown && "active"}`}
              disabled={disabledDropdown}
              onClick={handleDropdown}
            >
              The Firm
              <i className="air ai-angle-down ml-2" />
            </button>
            <div className="dropdown-menu">
              <Link
                className="dropdown-item"
                activeClassName="active"
                to="/about"
              >
                <span>About</span>
              </Link>
              <Link
                className="dropdown-item"
                activeClassName="active"
                to="/practice-areas"
              >
                <span>Practice Areas</span>
              </Link>
              <Link
                className="dropdown-item"
                activeClassName="active"
                to="/the-team"
              >
                <span>The Team</span>
              </Link>
              <Link
                className="dropdown-item"
                activeClassName="active"
                to="/recognitions"
              >
                <span>Recognitions</span>
              </Link>
              <Link
                className="dropdown-item"
                activeClassName="active"
                to="/work-with-us"
              >
                <span>Work With Us</span>
              </Link>
            </div>
          </li>
          <li className="nav-item">
            <Link className="nav-link" activeClassName="active" to="/resources">
              Resources
            </Link>
          </li>
          <li className="nav-item">
            <button
              className="nav-link"
              disabled={disabledSearch}
              onClick={handleSearch}
            >
              <i className="air ai-search" />
            </button>
          </li>
          <li className="nav-item">
            <Link to="/contact-us" className="btn btn-primary">
              {lang === "EN" ? "Contact" : "Kontak"}
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}

export default DesktopMenu
