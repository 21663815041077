import React, { useEffect, useContext } from "react"
import { Link } from "gatsby"

import { Container } from "components/anti/grid/grid"
import { LangContext } from "../../../../context/lang-context"

const MobileMenu = ({ mobileMenu, navExpand, variant, handleLang }) => {
  // Language state handler
  const { lang } = useContext(LangContext)

  return (
    <>
      <div className={`mobile-menu d-${navExpand}-none ${variant}`}>
        <div className="mobile-menu-content">
          <Container>
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  className="nav-link anim-1"
                  activeClassName="active"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <div className="nav-item">
                  <div className="nav-link no-icon anim-2">The Firm</div>
                </div>
                <div className="mobile-sub-menu">
                  <div className="nav-item">
                    <Link
                      className="nav-link anim-3"
                      activeClassName="active"
                      to="/about"
                    >
                      About
                    </Link>
                  </div>
                  <div className="nav-item">
                    <Link
                      className="nav-link anim-4"
                      activeClassName="active"
                      to="/practice-areas"
                    >
                      Practice Areas
                    </Link>
                  </div>
                  <div className="nav-item">
                    <Link
                      className="nav-link anim-5"
                      activeClassName="active"
                      to="/the-team"
                    >
                      The Team
                    </Link>
                  </div>
                  <div className="nav-item">
                    <Link
                      className="nav-link anim-6"
                      activeClassName="active"
                      to="/recognitions"
                    >
                      Recognitions
                    </Link>
                  </div>
                  <div className="nav-item">
                    <Link
                      className="nav-link anim-7"
                      activeClassName="active"
                      to="/work-with-us"
                    >
                      Work With Us
                    </Link>
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link anim-8"
                  activeClassName="active"
                  to="/resources"
                >
                  Resources
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link anim-8"
                  activeClassName="active"
                  to="/contact-us"
                >
                  {lang === "EN" ? "Contact" : "Kontak"}
                </Link>
              </li>
            </ul>
            <div className="mobile-lang">
              <button
                className={`nav-link anim-9 ${lang === "EN" && "active"}`}
                onClick={() => handleLang("EN")}
              >
                English
              </button>
              <button
                className={`nav-link anim-9 ${lang === "ID" && "active"}`}
                onClick={() => handleLang("ID")}
              >
                Bahasa Indonesia
              </button>
            </div>
          </Container>
        </div>
      </div>
    </>
  )
}

export default MobileMenu
